import { memoize } from "lodash-es"
import { useSyncExternalStore } from "react"

export function useGon<T>(key: string) {
  const store = getGonStore(key)
  return useSyncExternalStore(store.subscribe, store.getSnapshot) as T
}

const getGonStore = memoize((key: string) => {
  return {
    getSnapshot() {
      return gon[key]
    },
    subscribe(callback: () => void) {
      const listener = (e: DocumentEventMap["gonchanged"]) => {
        if (e.detail.keys.has(key)) callback()
      }
      document.addEventListener("gonchanged", listener)
      return () => document.removeEventListener("gonchanged", listener)
    },
  }
})

declare global {
  interface DocumentEventMap {
    gonchanged: CustomEvent<{ keys: Set<string> }>
  }
}
