import "./EvpQrScannable.css"
import { $onQrCodeScan, OnQrCodeScan } from "./QrScanner"

let userEventPromise:
  | Promise<typeof import("@testing-library/user-event")>
  | undefined

function getUserEventPromise() {
  userEventPromise ??= import("@testing-library/user-event")
  return userEventPromise
}

/**
 * Wrap an input box within an `<evp-qr-scannable>` element
 * to allow scanning QR codes into that input box.
 */
export class EvpQrScannable extends HTMLElement {
  private unsubscribe: (() => void) | undefined

  connectedCallback() {
    const button = document.createElement("button")
    button.className = "evp-qr-scannable-button"
    button.innerHTML = `<i class="fa fa-qrcode"></i>`
    button.type = "button"
    button.title = "Scan QR code"
    button.addEventListener("click", () => this.toggleScan())

    // Begin eager-loading the user-event package.
    // We don’t eager-load it if there’s no `<evp-qr-scannable>` element on the page.
    getUserEventPromise()

    if (gon.qr_scannable_unleashed) {
      this.appendChild(button)
    }

    this.unsubscribe = $onQrCodeScan.subscribe((currentHandler) => {
      this.dataset.active = currentHandler === this.handler ? "true" : "false"
    })
  }

  disconnectedCallback() {
    this.unsubscribe?.()
  }

  toggleScan() {
    if ($onQrCodeScan.get() === this.handler) {
      $onQrCodeScan.set(undefined)
    } else {
      $onQrCodeScan.set(this.handler)
    }
  }

  private handler: OnQrCodeScan = async (text: string) => {
    const input = this.querySelector("input")
    if (!input) {
      throw new Error("No input found")
    }

    // Simulate typing the text into the input box and press Enter.
    const { userEvent } = await getUserEventPromise()
    userEvent.clear(input)
    userEvent.type(input, text.replace(/\W/g, "") + "{Enter}")

    // Hide the QR code scanner.
    $onQrCodeScan.set(undefined)
  }
}

if (window.customElements) {
  window.customElements.define("evp-qr-scannable", EvpQrScannable)
}

declare global {
  interface HTMLElementTagNameMap {
    "evp-qr-scannable": EvpQrScannable
  }
  namespace JSX {
    interface IntrinsicElements {
      "evp-qr-scannable": React.DetailedHTMLProps<
        React.HTMLAttributes<EvpQrScannable>,
        EvpQrScannable
      >
    }
  }
}
