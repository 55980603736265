import "./QrScanner.css"
import { atom } from "nanostores"

export type OnQrCodeScan = (text: string) => void

/**
 * When set to a function, the QR code scanner will be shown.
 * When set to `undefined`, the QR code scanner will be hidden.
 */
export const $onQrCodeScan = atom<OnQrCodeScan | undefined>(undefined)

let scanner: QrScanner | undefined

class QrScanner {
  private div = document.createElement("div")
  private onMessage: (e: MessageEvent) => void
  constructor(onScan: OnQrCodeScan, onClose: () => void) {
    this.div.className = "evp-qr-scanner"
    this.div.innerHTML = `<div class="panel panel-default">
      <div class="panel-heading">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        Scan QR code
      </div>
      <div class="panel-body px-0 py-0">
        <iframe class="evp-qr-scanner-iframe" allow="camera"></iframe>
      </div>
    </div>`

    const iframe = this.div.querySelector("iframe") as HTMLIFrameElement

    // More info about the QR code scanner: https://github.com/dtinth/qr
    iframe.src =
      "https://scan-qr-code.vercel.app/?action=scan&fit=cover&delay=100&post=parent"

    this.onMessage = (e) => {
      if (e.source === iframe.contentWindow && e.data.text) {
        onScan(e.data.text)
      }
    }
    window.addEventListener("message", this.onMessage)
    this.div.querySelector(".close")?.addEventListener("click", onClose)

    document.body.appendChild(this.div)
  }
  dispose() {
    this.div.remove()
    window.removeEventListener("message", this.onMessage)
  }
}

$onQrCodeScan.subscribe((value) => {
  if (value && !scanner) {
    scanner = new QrScanner(
      (text) => {
        $onQrCodeScan.get()?.(text)
      },
      () => {
        $onQrCodeScan.set(undefined)
      },
    )
  } else if (!value && scanner) {
    scanner.dispose()
    scanner = undefined
  }
})
