/// <reference types="vite/client" />

import { isQueryFlagEnabled } from "@/utils"
import "../evp-html-embed"
import "../evp-mini-app"
import "../evp-qr-scannable"
import { registerIslandLoader } from "../evp-react-island"
import "../evp-support-toc"

for (const [path, loader] of Object.entries(
  import.meta.glob("../**/*.island.tsx"),
)) {
  const name = path.replace("../", "").replace(".island.tsx", "")
  registerIslandLoader(name, loader)
}

if (
  isQueryFlagEnabled("theme_editor") &&
  !document.getElementById("evp-theme-editor")
) {
  const div = document.createElement("div")
  div.id = "evp-theme-editor"
  document.body.appendChild(div)
  div.innerHTML = `<evp-react-island component="ui-kit/ThemeEditor"></evp-react-island>`
}
