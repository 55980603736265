import { QueryClientProvider } from "@tanstack/react-query"
import {
  ComponentType,
  createContext,
  FC,
  lazy,
  Suspense,
  useContext,
  useEffect,
  useRef,
} from "react"
import { createRoot, Root } from "react-dom/client"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import { queryClient } from "../react-query"

const loaders = new Map<string, ComponentType>()
const IslandContext = createContext<string>("(no island)")

interface Island {
  name: string
  props: any
}
const Island: FC<Island> = ({ name, props }) => {
  const Component = loaders.get(name)
  if (!Component) {
    return (
      <>{`Did not find island file at "app/frontend/${name}.island.tsx"`}</>
    )
  }
  return (
    <IslandContext.Provider value={name}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<></>}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Component {...props} />
          </ErrorBoundary>
        </Suspense>
      </QueryClientProvider>
    </IslandContext.Provider>
  )
}

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const sent = useRef(false)
  const island = useContext(IslandContext)
  useEffect(() => {
    if (!sent.current) {
      sent.current = true
      const airbrake = window.airbrake
      if (airbrake) {
        airbrake.notify({
          error,
          context: { component: island },
        })
      }
    }
  }, [error, island])
  return (
    <div className="panel panel-danger" role="alert">
      <div className="panel-heading">
        <h4>Something went wrong</h4>
      </div>
      <div className="panel-body">
        <pre>{error.message}</pre>
        <button className="btn" onClick={resetErrorBoundary}>
          Try again
        </button>
      </div>
    </div>
  )
}

class EvpReactIsland extends HTMLElement {
  private _reactRoot?: Root
  static observedAttributes = ["props"]

  connectedCallback() {
    this._reactRoot = createRoot(this)
    this._renderReactRoot()
  }
  disconnectedCallback() {
    this._reactRoot?.unmount()
  }
  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === "props" && this.hasAttribute("rerender-on-props-change")) {
      this._renderReactRoot()
    }
  }
  private _renderReactRoot() {
    this._reactRoot?.render(
      <Island
        name={this.getAttribute("component")!}
        props={JSON.parse(this.getAttribute("props") || "{}")}
      />,
    )
  }
}

if (window.customElements) {
  window.customElements.define("evp-react-island", EvpReactIsland)
}

export function registerIslandLoader(name: string, loader: () => Promise<any>) {
  loaders.set(name, lazy(loader))
}
