export class EvpSupportToc extends HTMLElement {
  connectedCallback() {
    import("./initializeSupportToc").then(({ initializeSupportToc }) => {
      initializeSupportToc(this)
    })
  }
}

if (window.customElements) {
  window.customElements.define("evp-support-toc", EvpSupportToc)
}
