import {
  MethodCallEvent,
  MiniAppIframeController,
} from "@eventpop-oss/frame-host"

/**
 * @see https://opn-ooo.atlassian.net/wiki/spaces/EV/pages/663095662/Mini-App
 */
export class EvpMiniApp extends HTMLElement {
  private iframe = document.createElement("iframe")
  private rpcHandlers = new Map<string, RpcMethodHandler>()
  private key?: string
  private currentController?: MiniAppIframeController
  private expectedOrigin = "https://eventpop.me"

  /**
   * To debug the communication between the parent and the child iframe,
   * set this property to true, or run `sessionStorage["evp-mini-app-debug"] = 1`
   * and refresh the page.
   */
  private debug = (() => {
    try {
      return !!+sessionStorage["evp-mini-app-debug"]
    } catch (error) {
      return false
    }
  })()

  constructor() {
    super()
    this.iframe.style.border = "0"
    this.iframe.style.width = "100%"
    this.iframe.style.display = "block"
    this.appendChild(this.iframe)

    this.registerRpcHandler("showOrderDetailEventpop", () => {
      const options = JSON.parse(this.getAttribute("options") as string)
      const orderDetailPath = `/orders/${options.order_id}/tickets`

      window.location.replace(orderDetailPath)
    })

    this.registerRpcHandler("getIdToken", (event) => {
      // We use jQuery to make an API request to the Rails app because
      // it is integrated with Rails’ CSRF protection.
      event.replyWithAction(async () =>
        $.getJSON(
          `/oidc/id_token?audience=${encodeURIComponent(
            this.expectedOrigin
          )}&${this.getAttribute("id-token-params")}`
        ).then((data) => ({ idToken: data.id_token }))
      )
    })

    this.registerRpcHandler("openNewTab", (res) => {
      let pathname = `${window.location.pathname}${res.params.url}`
      if (process.env.NODE_ENV !== "production") {
        pathname = `${pathname}?flags=debug`
      }
      const win = window.open(pathname, "_blank")
      if (win) {
        //Browser has allowed it to be opened
        win.focus()
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website")
      }
    })

    this.registerRpcHandler("showModal", (res) => {
      const modalUrl = new URL(
        res.params.url,
        this.getAttribute("url") as string
      ).toString()
      const $showModal = $("#showModal")
      if ($("#showModal").length > 0) {
        $showModal.find(".modal-body").children("evp-mini-app").remove()
        if (res.params.ticketInfo?.externalId) {
          $("#showModal .modal-body").append(
            `<evp-mini-app url="${modalUrl}" options="{}" locale="${I18n.locale}" id-token-params="ticket_id=${res.params.ticketInfo.externalId}" />`
          )
        } else {
          $("#showModal .modal-body").append(
            `<evp-mini-app url="${modalUrl}"  locale="${I18n.locale}" options="{}" />`
          )
        }
        $("#showModal").modal({ show: true })
      } else {
        /// first time render modal
        $(`<div id="showModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header" style="padding:16px 16px 0px">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body" style="padding:0 16px 16px"></div>
              </div>
            </div>
          </div>`).appendTo("body")
        if (res.params.ticketInfo?.id) {
          $("#showModal .modal-body").append(
            `<evp-mini-app url="${modalUrl}" options="{}" locale="${I18n.locale}" id-token-params=ticket_id=${res.params.ticketInfo.externalId}" />`
          )
        } else {
          $("#showModal .modal-body").append(
            `<evp-mini-app url="${modalUrl}" locale="${I18n.locale}" options="{}" />`
          )
        }

        $("#showModal").modal({ show: true })
      }
    })
  }

  // https://html.spec.whatwg.org/multipage/custom-elements.html#concept-custom-element-definition-observed-attributes
  static get observedAttributes() {
    return ["url", "options"]
  }

  // https://html.spec.whatwg.org/multipage/custom-elements.html#concept-custom-element-definition-lifecycle-callbacks
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks
  connectedCallback() {
    this.refresh()
  }

  // https://html.spec.whatwg.org/multipage/custom-elements.html#concept-custom-element-definition-lifecycle-callbacks
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks
  attributeChangedCallback() {
    this.refresh()
  }

  // https://html.spec.whatwg.org/multipage/custom-elements.html#concept-custom-element-definition-lifecycle-callbacks
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks
  disconnectedCallback() {
    this.refresh()
  }

  registerRpcHandler(method: string, handler: RpcMethodHandler) {
    this.rpcHandlers.set(method, handler)
  }

  refresh() {
    const url = this.getAttribute("url") as string
    const options = this.getAttribute("options") || ""
    const locale = this.getAttribute("locale") || ""
    const key = `${url},${this.isConnected}`
    if (this.key !== key) {
      this.key = key
      this.currentController?.dispose()
      if (this.isConnected) {
        this.currentController = new MiniAppIframeController(this.iframe, {
          url,
          options: { options, locale },
          routingEnabled: true,
          onMethodCall: (event) => {
            if (this.debug) {
              console.debug("[evp-mini-app] <<", event)
            }
            const handler = this.rpcHandlers.get(event.method)
            if (handler) {
              handler(event)
            } else {
              console.warn(`Unknown RPC method: ${event.method}`)
            }
          },
          onSendMessage: (event) => {
            if (this.debug) {
              console.debug("[evp-mini-app] >>", event)
            }
          },
        })
      } else {
        this.currentController = undefined
      }
    }
  }
}

export type RpcMethodHandler = (ctx: MethodCallEvent) => void

if (window.customElements) {
  window.customElements.define("evp-mini-app", EvpMiniApp)
}
